/*
 * @Descripttion: 说明
 * @version: V1.0
 * @Author: 刘小龙
 * @Date: 2022-04-26 16:13:20
 * @LastEditors: 刘小龙
 * @LastEditTime: 2022-04-26 16:13:21
 */
import { App } from 'vue'
import registerXicons from './register-xicons'

//app.use 默认执行其中的函数并且传入app对象
export function globalRegister(app: App): void {
    app.use(registerXicons)
}
