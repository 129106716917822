/*
 * @Descripttion: 全局注册xicons
 * @version: V1.0
 * @Author: T00038299
 * @Date: 2022-02-07 09:30:26
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-05-12 16:25:33
 */
import type { App } from 'vue'

import {
    QuestionCircle20Filled,
    Info20Filled,
    ReOrderDotsVertical16Regular,
    Emoji48Regular,
    Image48Regular,
    ArrowDownload16Regular,
    ArrowCircleLeft48Regular,
    GridDots24Regular,
    Timer20Regular,
} from '@vicons/fluent'

import { LikeOutlined, CommentOutlined } from '@vicons/antd'
import { AlertCircle } from '@vicons/tabler'
import { ArrowBack, SettingsOutline, CompassOutline, Checkmark, Add } from '@vicons/ionicons5'
import {
    MdArrowDropdown,
    MdArrowDropright,
    IosArrowDropright,
    IosMenu,
    IosArrowDroprightCircle,
} from '@vicons/ionicons4'

import {
    User,
    UserSimulation,
    UserProfile,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ChevronDown,
    Maximize,
    Information,
    Close,
    Search,
    Edit,
    BackToTop,
    InformationFilled,
    CloseFilled,
    VolumeUp,
    Settings,
    ArrowLeft,
    CloudDownload,
    Filter,
} from '@vicons/carbon'

export const icons = [
    // carbon
    User,
    UserSimulation,
    UserProfile,
    ChevronLeft,
    ChevronRight,
    ChevronUp,
    ChevronDown,
    Maximize,
    Information,
    Close,
    Search,
    Edit,
    BackToTop,
    InformationFilled,
    CloseFilled,
    VolumeUp,
    Settings,
    ArrowLeft,
    CloudDownload,
    Filter,
    // fluent
    QuestionCircle20Filled,
    Info20Filled,
    ReOrderDotsVertical16Regular,
    Emoji48Regular,
    Image48Regular,
    ArrowDownload16Regular,
    ArrowCircleLeft48Regular,
    GridDots24Regular,
    Timer20Regular,
    // antd
    LikeOutlined,
    CommentOutlined,
    // tabler
    AlertCircle,
    // ionicons5
    ArrowBack,
    SettingsOutline,
    CompassOutline,
    Checkmark,
    Add,
    // ionicons4
    MdArrowDropdown,
    MdArrowDropright,
    IosArrowDropright,
    IosMenu,
    IosArrowDroprightCircle,
]

// 按需导出 svg icon 图标组件
export default function (app: App): void {
    for (const icon of icons) {
        app.component(icon.name, icon)
    }
}
