/*
 * @Descripttion: 全局配置文件
 * @version: V1.0
 * @Author: liuxiaolong
 * @Date: 2022-05-10 11:01:20
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-05-25 18:19:44
 */
export default {
    baseUrl: '',
    // 旧版本HR助手项目地址
    oldHrPortalUrl: location.origin.indexOf('corp') >= 0 ? '//hrportal.corp.vipshop.com' : '//hrportal.sit.vipshop.com',
    // oldHrPortalUrl: location.origin.indexOf('corp') >= 0 ? '//hrportal.corp.vipshop.com' : '//10.83.67.112:9093',
    // okr项目地址
    okrUrl: location.origin.indexOf('corp') >= 0 ? '//okr.corp.vipshop.com/#/myOkr' : '//okr.sit.vipshop.com/#/myOkr',
}
