import { createDynamicRouteGuard } from './dynamic'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'

/**
 * 处理路由页面权限
 */
export async function createPermissionGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    const permission = await createDynamicRouteGuard(to, from, next)

    next()
}
