import { watch } from 'vue'
import { useUserInfo } from '@/stores/userInfo'
import Config from '@/config'
import router from '@/router'
import type { Component } from 'vue'
import type { RouteRecordRaw } from 'vue-router'
import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router'
type ViewComponent = Record<string, () => Promise<Component>>
import * as API from '@/yapi'

const hasRoles = true

export async function createDynamicRouteGuard(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    const userInfo = useUserInfo()
    if (userInfo.userInfo) {
        return false
    }
    // 先不走动态添加路由
    // const data = await userInfo.setUserInfo()
    // if (Object.keys(data).length) {
    //     const menus = getMenuData(userInfo.userInfo!)
    //     const routers = transformMenuToVueRoute(menus)
    //     addRoute(routers!, to)
    //     userInfo.setMenus(menus)
    // }
}

/**
 * 添加路由
 * @param routers RouteRecordRaw[] 动态路由列表
 * @param to RouteLocationNormalized
 */
function addRoute(routers: RouteRecordRaw[], to: RouteLocationNormalized) {
    routers.map((route) => {
        router.addRoute(route)
    })
    // 路由添加进去了没有及时更新 需要重新进去一次拦截
    router.push({ ...to, replace: true })
}

/**
 * 获取路由组件
 * @param routerName string
 * @returns ViewComponent
 */
function getRouterCompoent(routerName?: string) {
    if (!routerName) {
        window.console.error(`路由“${routerName}”没有对应的组件文件！`)
    }
    const importViews = import.meta.glob(`../views/**/index.vue`) as ViewComponent
    const routerKeyReg = new RegExp(routerName!, 'g')
    const viewKeys = Object.keys(importViews).filter((key) => routerKeyReg.test(key.toLocaleLowerCase()))
    return () => setViewComponentName(importViews[viewKeys[0]], routerName!)
}

/**
 * 给页面组件设置名称
 * @param asyncComponent () => Promise<Component> 动态组件
 * @param name string 路由名
 * @returns Component
 */
async function setViewComponentName(asyncComponent: () => Promise<Component>, name: string) {
    const component = (await asyncComponent()) as { default: Component }
    Object.assign(component.default, { name })
    return component
}

/**
 * 转换菜单为vue路由
 * @param menus  Menu.MenuItem[] 菜单
 * @returns RouteRecordRaw[] vue路由
 */
function transformMenuToVueRoute(menus: Menu.MenuItem[]) {
    const _routes = <RouteRecordRaw[]>[]
    if (menus.length) {
        menus.map((menu) => {
            const params = {
                name: menu.name,
                path: `/${menu.name}`,
                meta: {
                    title: `check价 - ${menu.label}`,
                },
                component: getRouterCompoent(menu.name),
            }
            _routes.push(params)
        })
        return _routes
    }
}

/**
 * 获取菜单数据
 * @param res API.GetApiEmployeeGetInfoAndRoleResponse 接口权限数据
 * @returns Menu.MenuItem[]
 */
function getMenuData(res: API.GetApiEmployeeGetInfoAndRoleResponse) {
    const _menus: Menu.MenuItem[] = [
        {
            name: 'staff',
            label: '员工',
            // path: Config.oldHrPortalUrl,
            path: '/#/',
            icon: 'user',
        },
    ]
    if (res.isHRBP) {
        _menus.push({
            name: 'hrbp',
            label: 'HR',
            path: `${Config.oldHrPortalUrl}/hrbp/list`,
            // path: '/#/hrbp',
            icon: 'user-simulation',
        })
    }
    if (res.isManager) {
        _menus.push({
            name: 'manager',
            label: '管理者',
            path: '/#/manager',
            icon: 'user-profile',
        })
    }
    return _menus
}
