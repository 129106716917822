/*
 * @Descripttion: 用户信息模块
 * @version: V1.0
 * @Author: T00038299
 * @Date: 2022-04-21 10:33:05
 * @LastEditors: liuxiaolong
 * @LastEditTime: 2022-07-06 15:27:52
 */
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { closeWindow } from '@/util'

import * as API from '@/yapi'

export const useUserInfo = defineStore('userInfo', () => {
    const _userInfo: API.PostParityApiLoginIndexResponse = localStorage.getItem('userInfo')
        ? JSON.parse(localStorage.getItem('userInfo') || '')
        : ''
    const userInfo = ref<API.PostParityApiLoginIndexResponse | undefined>(_userInfo || {})
    const setUserInfo = (data?: API.PostParityApiLoginIndexResponse) => {
        localStorage.setItem('userInfo', JSON.stringify(data))
        userInfo.value = data
    }

    return {
        userInfo,
        setUserInfo,
    }
})
